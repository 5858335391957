<template>
    <div>
        <div class="sm:flex items-center w-full">
            <!-- Country select -->
            <div class="w-full sm:w-32 flex-shrink-0 mr-2">
                <SurveySelect :question="countryCodeQ" :surveyId="surveyId" imageSelect @update="countryUpdate" />
            </div>
            <!-- Number input -->
            <div class="portal-input rounded-md flex-grow mt-1 sm:mt-0">
                <span
                    data-cy="country-call-code"
                    class="absolute left-0 top-0 bottom-0 flex items-center w-16 pl-3 border-r border-mid-grey text-base"
                >
                    +{{ callCode }}
                </span>
                <input
                    v-model="phoneInput"
                    :data-cy="`${qName}-input`"
                    class="
                        input
                        w-full
                        rounded-md
                        appearance-none
                        pr-3
                        pl-20
                        py-2
                        border border-mid-grey
                        high-c-border-black
                        bg-transparent
                        text-base
                    "
                    type="text"
                    :name="qName"
                    @input="phoneInputTouched = true"
                    @keyup.enter="nextClick"
                />
                <div class="focus-border"></div>
            </div>
        </div>
        <div class="mb-2" data-cy="phone-validation">
            <div
                class="text-sm leading-none text-center text-lighter mt-1 sm:mt-3 bg-warn text-over-warn flex items-center justify-center rounded-lg"
                style="min-height: 2rem"
                :class="{ 'opacity-0': !phoneValidationErr || !phoneInputTouched }"
            >
                <span class="block px-3 py-px">{{ phoneValidationErr }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import iso2codes from "@/../../shared/js/countryCodes";
import { isValidPhoneNumber, validatePhoneNumberLength, getCountryCallingCode, parsePhoneNumber } from "libphonenumber-js";
import debounce from "lodash.debounce";
import SurveySelect from "./surveySelect";
import LazyLoadingBGimage from "@/components/ui/lazyLoadingBGimage";
import text from "./phoneNumberTextboxText";

export default {
    name: "PhoneNumberTextbox",
    props: {
        question: {
            type: Object,
            required: true,
        },
        surveyId: String,
        // autocomplete_name: String,
        // Mostly used where we have multiple textboxes together (see EasypostAddress.vue) so we don't want to repeat error messages
        // icon_error_indicator: Boolean,
        // required: Boolean,
    },
    components: {
        SurveySelect,
        LazyLoadingBGimage,
    },
    data() {
        return {
            phoneInput: null,
            country: null,
            phoneInputTouched: false,
            strings: text,
        };
    },
    watch: {
        ccPlusNumber(val) {
            // If either the country dropdown or the number field has changed
            console.log("got change", val);
            this.handleChangeAfterDebounce(this);
        },
    },
    computed: {
        isRequired() {
            return this.question.isRequired;
        },
        qName() {
            return this.question.name;
        },
        existingInput() {
            return this.question.userAnswer;
        },
        countryCodeQ() {
            // The survey selector expects most info to be in a 'question' object
            return {
                name: `${this.qName}-cc`,
                choices: iso2codes.map((obj) => {
                    return {
                        value: obj.value,
                        text: obj.value,
                        imgUrl: `http://purecatamphetamine.github.io/country-flag-icons/3x2/${obj.value}.svg`,
                        altText: obj.text,
                    };
                }),
                userAnswer: this.country,
                placeholder: "...",
            };
        },
        callCode() {
            if (!this.country) return null;
            return getCountryCallingCode(this.country);
        },
        ccPlusNumber() {
            if (!this.callCode || !this.phoneInput) return null;
            return `+${this.callCode} ${this.phoneInput}`;
        },
        phoneValidationErr() {
            let vError = null;
            // If the number is not required, only validate if there is input
            if (this.isRequired || this.phoneInput) {
                console.log(this.phoneInput, "phoneInput");
                if (!this.country) vError = this.strings["country-err"];
                else if (!this.phoneInput) vError = this.strings["missing-err"];
                else {
                    // If we have a number and country code, validate it!
                    const validLengthError = validatePhoneNumberLength(this.ccPlusNumber);
                    if (validLengthError) {
                        vError = this.strings["num-errors"][validLengthError];
                    } else {
                        const isValid = isValidPhoneNumber(this.ccPlusNumber);
                        if (!isValid) vError = this.strings["invalid-err"];
                    }
                }
            }
            return vError;
        },
    },
    created() {
        if (this.existingInput) {
            const parsedNo = parsePhoneNumber(this.existingInput);
            console.log(parsedNo);

            this.phoneInput = parsedNo.nationalNumber;
            this.country = parsedNo.country;
        } else {
            // Use the question default country, or UK if none is set (as most sano users are still UK based)
            this.country = this.question.defaultCountry ? this.question.defaultCountry : "GB";
        }
    },
    methods: {
        countryUpdate(input) {
            this.country = input.val;
            this.phoneInputTouched = true;
        },
        handleChangeAfterDebounce: debounce((vm) => {
            if (vm.phoneValidationErr) {
                vm.$emit("phonefield-error", true);
                console.log("emit error");
            } else {
                vm.$emit("phonefield-error", false);
                console.log("emit input");
                vm.$emit("update", {
                    surveyId: vm.surveyId,
                    qName: vm.qName,
                    val: vm.ccPlusNumber,
                });
            }
        }, 200),
        nextClick() {},
    },
};
</script>
